// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_9175 #slider {
  height: 100vh;
  max-height: 1080px;
  min-height: 550px;
  position: relative;

  @media only screen and (max-width: 990px) {
    height: 700px;
    min-height: 700px;
  }

  &:after {
    @include before(100%, 450px);
    background: url(/i/design/gradient-bottom.png) repeat-x bottom left;
    bottom: 0;
    left: 0;
  }
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  min-height: 450px;
  position: relative;
  z-index: 1;

  @media only screen and (max-width: 990px) {
    height: 450px;
    min-height: 450px;
  }

  &:before {
    @include before(100%, 450px);
    background: url(/i/design/gradient.png) repeat-x top left;
    top: 0;
    left: 0;
  }
}

.backstretch {
  position: relative;

  &:before {
    position: absolute;
    content: '';
    background: rgba(#18335E, .3);
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  img {
    top: 0px !important;
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-size: 34px;
  line-height: 1;
  z-index: 99;
  font-weight: 600;
  padding: 0 20px;

  @media only screen and (max-width: 990px) {
    font-size: 25px;

    br {
      display: none;
    }
  }
}